module.exports = {
	_init: function() {
		$(".sticky-nav").scrollClass({
			className: "affix", 
			condition: function(scroll) {
				if (!$(this).data('position') ) {
					var offset = $(this).data("offset")
					if ( typeof offset !== "number" ) {
						offset = $(offset).length ? $(offset).outerHeight() : 0;
					}
					offset = $(this).offset().top - offset;
					$(this).data('position', offset);
				}

				return scroll >= $(this).data('position');
			},
			
			refresh: function(scroll) {
				var offset = $(this).data("offset")
				if ( typeof offset !== "number" ) {
					offset = $(offset).length ? $(offset).outerHeight() : 0;
				}
				
				if ($(this).hasClass("affix")) {
					var $clone = $(this).clone()
					$clone
						.removeClass("affix")
						.css("visibility", "hidden")
						.insertBefore($(this))
					offset = $clone.offset().top - offset;
					$clone.remove();
				} else {
					offset = $(this).offset().top - offset;
				}
				
				$(this).data('position', offset);
			},
		});

		$(document).on("resize.stickynav." + this.id, _.debounce(function() {
			$(".sticky-nav").scrollClass("refresh");
		}, 500));
	},

	_destroy: function() {
		$(document).off("resize.stickynav." + this.id);
	},
}