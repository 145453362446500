module.exports = {

	showTeamNav: function(data) {
		if (this.route.match("/team\/[^/]*/")) {
			// Must return true or undefined, the value "false" is truthy in PHP
			data['show-nav'] = !$(".mini-team-list").length ? true : undefined
		}
		return data;
	},

	showFaqNav: function(data) {
		if (this.route.match("/how-we-work\/[^/]*/")) {
			// Must return true or undefined, the value "false" is truthy in PHP
			data['show-nav'] = !$(".faqs-nav").length ? true : undefined
		}
		return data;
	},

	setAjaxOrigin: function(data) {
		if (!data.hasOwnProperty('ajax-origin')) {
			path   = this.location;
			search = path.match("(/[^/]*)");
			data['ajax-origin'] = search[1];
		}
		return data;
	},

}
