var ModuleLoader = function(extensions) {
	for (var extension in extensions) {
		this[extension] = extensions[extension]
	}
	this.createTriggers();
	typeof this.initialize === 'function' && this.initialize.call(this);
	this._reload();
};

ModuleLoader.prototype.queue     = [];
ModuleLoader.prototype.active    = {};
ModuleLoader.prototype.assembled = [];
ModuleLoader.prototype.toDestroy = [];
ModuleLoader.prototype.triggers  = {};
ModuleLoader.prototype.modules   = {};
ModuleLoader.prototype.search    = "body";
ModuleLoader.prototype.uuid      = 0;

ModuleLoader.prototype._reload  = function (search) {
	var self   = this;
	this.queue = {};
	search     = search || this.search

	this.queue = this._findModules(search);

	this._deactivateModules();
	this._activateModules();
	typeof this.reload === 'function' && this.reload.call(this);
};

ModuleLoader.prototype._getModuleName = function(name, selector) {
	return $.camelCase( name + "-" + $(selector).attr("id"));
};

ModuleLoader.prototype.createTriggers = function() {
	for (var trigger in this.triggers) {
		this.createTrigger(this.triggers[trigger]);
	}
};

ModuleLoader.prototype.createTrigger = function(data) {
	var self = this;
	data.delegate = data.delegate ? data.delegate : null;
	$(document).on(data.event, data.delegate, function(event) {
		var search = data.searchTarget ? event.target : false;
		self.doAction(data.actions, arguments);
		data.reload && self._reload(search);
	});
}

ModuleLoader.prototype.doAction = function( actions, data ) {
	for (var func in actions) {
		actions[func].apply(this, data);
	}
};

ModuleLoader.prototype._activateModules = function() {
	for (var module in this.queue) {
		this._activateModule( this.queue[module] );
	}
	this.queue = [];
};

ModuleLoader.prototype._activateModule = function(module) {
	if ($.inArray(module.name, this.assembled) === -1) {
		typeof module._setup === 'function' && module._setup.call(module, module.opts);
		this.assembled.push(module.id);
	}

	if ( !(module.id in this.active) )  {
		typeof module._init === 'function' && module._init.call(module, module.opts);
		this.active[module.id] = module;
	}
};

ModuleLoader.prototype._deactivateModules = function() {
	var self = this;
	for (var name in this.toDestroy) {
		this._deactivateModule( this.toDestroy[name] );
	}
	this.toDestroy = [];
};

ModuleLoader.prototype._deactivateModule = function(module) {
	if (module.id in this.active)  {
		var existingModule = this.active[module.id];
		typeof existingModule._destroy === 'function' && existingModule._destroy.call(existingModule);
		delete this.active[module.id]
	}
};

ModuleLoader.prototype._findModules = function (el) {
	var self = this
	,	list = [];
	$modules = $(el).find("[data-module]")

	$modules.each(function() {
		var modules = self._createModules( $(this) );
		$.extend(list, modules);
	});
	return list;
};

ModuleLoader.prototype._createModules = function ($el) {
	var list = {};
	var data = $el.data();
		delete data.module;

	for (prop in data) {
		if (prop.substring(0, 6) == "module") {
			var name        = prop.replace("module", "").toLowerCase()
			var module      = this._createModule(name, $el, data[prop])
			list[module.id] = module;
		}
	}
	return list;
}

ModuleLoader.prototype._createModule = function (name, selector, opts) {
	this._uniqueId( $(selector) );
	var data = { 
		name: name, 
		$el: $(selector),
		opts: opts,
		id: this._getModuleName(name, selector)
	}
	return $.extend(data, this.modules[name]);
}

ModuleLoader.prototype._uniqueId = function ( $el ) {
	if ( !$el.attr("id") ) {
		return $el.attr("id", "ml-id-" + ( ++this.uuid ));
	}
};


module.exports = ModuleLoader;