var utils = require("../utils")

module.exports = {

	teamBack: function($target) {
		// If we are navigating directly to the team page, from a team member url
		if (this.route.match("/team/$") && $target.attr("id") !== "/about/team/") {
			// Then set the team section as our section
			$target = utils.id2jq("/about/team/")
		}
		return $target;
	}
	
}
