module.exports = {
	_setup: function() {
	},

	_init: function() {
		$(".header").removeClass("full").scrollClass({
			className: "full", 
			condition: function(scroll) {

				var cHeight = $(".banner-carousel").height()
				,	tHeight = cHeight - $(this).height()

				return scroll >= tHeight;
			},
		});
		$(".primary-navbar").find(".active").removeClass("active");
	},

	_destroy: function() {
		// We have to check that the scrollClass had time to
		// initiate, otherwise quick navigating causes errors
		if ( $(".header").data( "scrollClass" ) !== undefined ) {
			$(".header").scrollClass("destroy").addClass("full");
		}
	},
}