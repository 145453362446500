var utils = require("../utils");

module.exports = {
	routes: [],

	_init: function() {
		var self    = this;
		this.$arrow = $( this.$el.data("indicator") );
		
		this.getRoutes()
		this.initialArrowPosition();
		
		utils.Router.on("route", this.maybeNavScroll, this)
		utils.Router.on("scroll", this.unpause, this);

		this.$el.on("navspy:activate", function(event, $new) {
			self.$arrow.jSlide($new, true);
		});

		this.$el.navspy({
			top: utils.pageOffset(),
			bottom: 10,
			create: function(event) {
				$(document).on("resize.navspy." + self.id, function() {
					self.$el.navspy("refresh");
				});
			},
		})
	},

	_destroy: function() {
		utils.Router.off(null, this.maybeNavScroll);
		utils.Router.off(null, this.unpause);
		$(document).off("resize.navspy." + this.id);
	},

	unpause: function() {
		this.$el.navspy("unpause");	
	},

	getRoutes: function() {
		var self = this;
		this.$el.find("a").each(function() {
			self.routes.push( $(this).attr("href") )
		});
	},

	maybeNavScroll: function(route, data) {
		var url = "/"+data[0];
		if ( $.inArray(url, this.routes) !== -1 ) {
			this.navScroll(url);
		}
	},

	// Temporarily override NavSpy to prevent a delay in active state.
	navScroll: function(url) {
		var $new = this.$el.find("[data-spy-on]").has('[href="'+url+'"]')

		this.$el.navspy("pause");
		this.$el.navspy("deactivate", this.$el.find(".active"));
		this.$el.navspy("activate", $new);
	},

	initialArrowPosition: function() {
		var $slideTo = this.$el.find(".active");

		if (!$slideTo.length) {
			$slideTo = this.$el.find("[data-spy-on]:first").first();
		}

		this.$arrow.jSlide($slideTo, false);
	},

}