module.exports = {

	blogSearch: function(load) {
		if (this.route.match("^blog")) {
			toCat    = this.route.match("^blog\/category\/.*")
			toSearch = this.route.match("^blog\/search\/.*")
			isList   = this.location.match("^\/blog\/(?:(category|search)\/[^/]*\/?)?$")

			load = (toCat && isList) || (toSearch && isList) ? false : true;
		}
		return load;
	},

	work: function(load) {
		if (this.route.match("^work") ) {
			toFilter = this.route.match("^work\/type\/.*")
			toList   = this.route.match("^work\/$")
			isList   = this.location.match("^\/work\/(?:type\/[^/]*\/?)?$")

			load = (toFilter && isList) || (toList && isList) ? false : true;
		}
		return load;
	},

	teamBack: function(load) {
		if ( 
			this.route.match("team/$") 
			&& this.location.match("^/about/team/[^/]+/") 
			&& this.eventType !== "click" ) 
		{
			load = true;
		}
		return load;			
	},

}

