module.exports = {

	leftright: function(transition) {
		if (transition === "leftright") {
			
			var proxy   = this.$target.data("scroll-proxy") 
			,	$el     = proxy ? $(proxy) : this.$target
			,	$nav    = $el.find(".subnav")
			,	$active = $nav.find(".active")
			,	$new    = $nav.find('[href="/'+this.route+'"]')

			if ($active.length && $new.length) {
				var	oldOffset = $active.offset().left
				,	newOffset = $new.offset().left;

				transition = newOffset > oldOffset ? "left" : "right";
			} else {
				transition = "bottom";
			}
		}
		return transition;
	}
	
}
