(function (global){
var navspy     = require("./modules/navspy");
var util       = require("./utils");
var LinkParser = require("./utils/linkparser");
var Backbone   = (typeof window !== "undefined" ? window['Backbone'] : typeof global !== "undefined" ? global['Backbone'] : null);
// Backbone.$     = require('jquery');

/* ==========================================================================
	Backbone App
	========================================================================= */

	var AppRouter = Backbone.Router.extend({
		/**
		 * An array to store URLs to keep track of the history.
		 *
		 * This array is used to keep track of all of the URLs that have been routed to.
		 * This includes the initial URL, because the router is called on page load.  
		 * This is an important piece of data to pass to the LinkParser. At one point,
		 * this was also used for back button support.
		 * 
		 * @type {Array}
		 *
		 * @todo Determine if this is still used for back button support, and if not
		 * determine if it can be replace by a single value instead of an array.
		 */
		history: [],

		/**
		 * The Backbone routes object.
		 *
		 * This is a "magic" object from Backbone used to determine which method to call.
		 * The key for each key/value pair can be set to a URL that will be passed to 
		 * the router, sans a leading slash.  The value is the name of a method to call
		 * when that URL is passed.  For instance, the URL "/" (which should route to the 
		 * homepage), when passed to the router has the leading slash removed, 
		 * and comes through as an empty string.  Thus we call the "index" method when that
		 * is passed.  For all other URLs we have set the default using the "*actions"
		 * key, which will catch all URLs that are not caught beforehand.  The router uses
		 * the hierarchy of the object to determine which routes to check first, so moving
		 * the *actions route to the top would prevent any other URL from matching.
		 * 
		 * @type {Object}
		 */
		routes: {
			'' : "index",
			'*actions': 'transfer',
		},

		/**
		 * Runs the first time the router is called.
		 *
		 * This is a "magic" function from backbone, that runs automatically the 
		 * first time the router is called.  In our case, it is only used to tell 
		 * our transfer method and before methods that this is the page load, and
		 * not a normal call to change the URL, thus preventing the router from
		 * attempting to load any content or parse a link that was not passed.
		 */
		initialize: function() {
			this.jump(window.location.pathname.substr(1))
			this.history.push(window.location.pathname)
		},

		/**
		 * This method is used to add functionality before/after a route is called.
		 *
		 * First it sets a LinkParser object on the app so we can parse our link.  
		 * Then after the route is called it fires a google analytics page view.  It also
		 * sends the previous URL to the history array, so that it can be used during a 
		 * press of the back button.
		 * 
		 * @param  {function} callback The route method that was matched.
		 * @param  {mixed} 	  args The arguments passed to the callback function.
		 */

		execute: function(callback, args) {
			var fragment = args[0];

			this.parseLink(fragment);

			// Execute backbone's normal routing
			if (callback) callback.apply(this, args);
	
			_gaq.push(['_trackPageview', fragment]);
	
			// When there's time to test, replace this with fragment
			// fragment is identical, but lacks a leading slash
			this.history.push(window.location.pathname)
			delete this.Parse; 		// garbage collection
			delete this.eventType; // reset the event, helper for back button detection.
		},

		/**
		 * This function retrieves the previous URL using the history array.
		 * 
		 * @return {String} The URL routed to prior to the current URL.
		 */
		referrer: function() {
			return this.history[this.history.length-1]
		},


		/**
		 * Parses the route based on current location and destination
		 * @param  {string} route The relative destination url.
		 */
		parseLink: function(route) {
			var filters = {
				data: 		require("./filters/data"),
				load: 		require("./filters/load"),
				target: 	require("./filters/target"),
				transition: require("./filters/transition"),
			}
			this.Parser = new LinkParser(route, this.referrer(), this.eventType, filters);
		},

		/**
		 * This is a speical router for navigating to the homepage.
		 *
		 * When this router is called it automatically calls the load method with
		 * an empty string as it's Fragment, which will retrieve the homepage 
		 * content.  This special route is used because both the LinkParser and the
		 * backbone app have some small issues routing to the homepage, since it
		 * often comes through as an empty string, or strictly as a forward slash.
		 */
		index: function() {
			this.load("");
		},

		/**
		 * This method is used to handle the logic of which route we want to take.
		 *
		 * In an ordinary backbone application, backbone will handle deciding which
		 * route to take on its own.  It does this using the new URL that is passed
		 * to its router.  Unfortunately in our application we can't take advantage
		 * of that.  In our application, the route is determined based on a variety 
		 * of factors not availabe to the backbone logic, primarily determined by 
		 * the LinkParser.  Therefore we have this single default route which will
		 * determine what route to call based on the LinkParser.
		 * 
		 * @param  {String} fragment The new URL to be routed to.
		 */
		transfer: function(fragment) {
			if (!this.Parser.load) {
				this.scroll(fragment);
			} else {
				this.load(fragment);
			}
		},

		/**
		 * This method is called on the initial route.
		 *
		 * This method is called on the initial route in order to set the viewport
		 * to the proper content, without animating scrolling to the target. 
		 * 	
		 * @param  {String} fragment The URL of the content, used to identify the target element.
		 */
		jump: function(fragment) {
			$section = this._maybe_proxy( util.id2jq("/" + fragment) );
			$section.elScroll({
				offset: util.pageOffset(),
				duration: 0,
			});
		},

		/**
		 * This method is called when the route is on the existing page.
		 *
		 * This method is called when the content does not need to be loaded,
		 * and we want to smoothly scroll down to the content that is on the 
		 * page.
		 * 	
		 * @param  {String} fragment The URL that has been set, used to identify the target element.
		 */
		scroll: function(fragment) {
			var self    = this;
			var $target = typeof this.Parser.$target !== "undefined" ? this.Parser.$target : util.id2jq("/"+fragment);
				$target = this._maybe_proxy( $target );

			$target.elScroll({
				offset: util.pageOffset(),
				complete: function() {
					self.trigger("scroll", fragment)
				}
			});
		},

		/**
		 * This route controls content loading via AJAX.
		 *
		 * Switching between pages on the site requires loading content via AJAX,
		 * and this route handles that.  This router will prepare the AJAX route
		 * and then pass data from the LinkParser to the pushLoad jQuery plugin
		 * which handles both the loading and the displaying of new content.
		 * 
		 * @param  {String} fragment The URL that is being routed to.  /ajax/ is 
		 * prepended to this when passed to the pushLoader.
		 */
		load: function(fragment) {
			this.Parser.$target.pushLoad({
				transition: this.Parser.transition,
				ajax: {
					url: "/ajax/"+fragment,
					data: this.Parser.ajax_data,					
				}
			});
		},

		_maybe_proxy: function($target) {
			if ( $target.data("scroll-proxy") ) {
				$target = $( $target.data("scroll-proxy") )
				$target = $target.length ? $target : $target;
			}
			return $target;
		},

	});

	module.exports = AppRouter;

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})