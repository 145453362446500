/**
 * The LinkParser object retrieves information about a local url to
 * determine how/if/where it should be loaded in the Jake website.
 *
 * The object has a number of methods that are meant to retrieve info
 * for the app router to determine firstly if the content has already 
 * been loaded, and if not whether it loads within the current page
 * or completely replaces the existing content.
 * 
 * @param  {String} url      The URL to parse.
 * @param  {String} location The current URL.
 * 
 * @return {LinkParser}      A New LinkParser Object.
 */
var LinkParser = function(url, location, trigger, filters) {
	this.target    = url ? this._leadingSlashIt(url) : "/";
	this.location  = location;
	this.eventType = trigger;

	this.filters = $.extend({}, this.filters, filters);

	this._set_route(this.target);
	this._find_target();
	this.$target = this._get_$target();

	this.load = this._maybeLoad();
	if (this.load) {
		this.transition = this._get_transition();
		this.ajax_data  = this._get_ajax_data_obj();
	}
}

LinkParser.prototype.target     = null
LinkParser.prototype.$target    = null
LinkParser.prototype.route      = null
LinkParser.prototype.load       = false
LinkParser.prototype.transition = null
LinkParser.prototype.ajax_data  = {}

// Fix this
LinkParser.prototype.filters    = {
	"load": {},
	"data": {},
	"target": {},
	"transition": {},
}

/**
 * Ensures that the given url has a leading slash
 *
 * This is important because without a leading slash on the url
 * the _find_target method can result in an infinite loop. If the 
 * string already has a leading slash the stringis returned untouched.
 * 
 * @param  {String} string The url to add the slash to
 * @return {String}        The url with a leading slash in front.
 */

LinkParser.prototype._leadingSlashIt = function(string) {
	return !string.match("^/") ? "/"+string : string;
}

/**
 * This function determines whether the given url should result in
 * an AJAX request.
 *
 * This method first checks a standard condition to determine if the
 * url should load, but the runs the result through a set of user
 * defined filters, which allow for special cases when a url would
 * normally result in a load or not load, but you may want to
 * override that behavior based on certain conditions.
 * 
 * @return {Boolean} true if it should load, false if not.
 */
LinkParser.prototype._maybeLoad = function() {
	var condition = this.target.indexOf(this.route) === -1
				 || this.$target.attr("id") === "/"
				 
	return this._runFilters("load", condition);
}

/**
 * Sets the route which should be passed to the Backbone Router.
 *
 * Cleans up the route so that backbone will always recieve the
 * same string even if the user is in IE, when backbone routes 
 * with the hashbang.
 * 
 * @param {String} target The target route to send to Backbone.
 */
LinkParser.prototype._set_route = function(target) {
	this.route = target.replace(/^\//, '').replace('\#\!\/', '')
},

/**
 * Finds the correct div or section of content to replace when loading
 * new content.
 *
 * This function uses a while loop to iterate over the given target (or 
 * URL) to find the div or section on the page which matches the URL.  It
 * chops off the last section of the URL and then looks for a DIV with 
 * that ID, if no div is found, it will chop off the last section and then
 * search again.  Most iterations will end with target being = to "/", which
 * is the div which contains all of a given page's content.  This means that
 * all of the content will be replaced with the new content.
 */
LinkParser.prototype._find_target = function() {
	var search = new RegExp('/[^/]*/$')
	,	found  = false;

	if (this.target.substring(this.target.length-1) !== "/") {
		this.target = this.target+"/";
	}

	while (!found && this.target != "/" && this.target ) {
		found = $('[id="'+this.target+'"]').length;
		this.target = found ? this.target : this.target.replace(search, "/");
	}
}

/**
 * Sets the target element.
 *
 * The target element is the div or section of the page which should be
 * replaced with the new content when the load completes.
 */
LinkParser.prototype._get_$target = function() {
	$target = $('[id="'+this.target+'"]');
	$target = this._proxy_check($target);
	$target = this._runFilters("target", $target);
	return $target;
}

/**
 * This retrieves a string which determines what transition the 
 * pushLoad object should use when transitioning between new and
 * old content.
 * 
 * @return {String} The name of a registered transition in the PushLoader.
 */
LinkParser.prototype._get_transition = function() {
	trans = this.$target.data("load-transition") ? this.$target.data("load-transition") : 'bottom';
	trans = this._runFilters("transition", trans);
	return trans;
}

/**
 * Retreives data to be passed to the AJAX call.
 *
 * This allows us to set extra data on an individual ajax call, which
 * means we can send extra query parameters to the server based on
 * certain conditions.
 * 
 * @return {Object} An object of data to pass to the jQuery.ajax call.
 */
LinkParser.prototype._get_ajax_data_obj = function() {
	var content = this.$target.data("load-data")
	,	strings = content ? content.split(" ") : []
	,	data    = {}

	$.each(strings, function(i, val) {
		data[val] = true;
	});

	data = this._runFilters("data", data);
	return data;
}

/**
 * This utility function runs a set of user defined filters on
 * certain actions.	
 *
 * This function is designed to be extended easily and quickly
 * when needed.  The "type" parameter allows for the easy creation
 * of additional filter types while ensuring that the mechanism for
 * running the filters remains constant.
 * 
 * @param  {String} type The type of filter to run.
 * @param  {Mixed}  data The data to be filtered.
 * @return {Mixed}       The filtered data.
 */
LinkParser.prototype._runFilters = function( type, data ) {
	if ( typeof this.filters[type] !== "undefined" ) {
		for (var func in this.filters[type]) {

			data = this._runFilter(func, type, data);
		}
	}
	return data;
}

/**
 * Runs data through an individual filter.
 *
 * Uses the type and function name to find the string within the 
 * internal filters object, and then passes the data to that function.
 * 
 * @param  {String} func The name of the function that will filter the data.
 * @param  {String} type The type of filter to be run.
 * @param  {Mixed}  data The data to be filtered.
 * @return {Mixed}       The filtered data.
 */
LinkParser.prototype._runFilter = function(func, type, data ) {
	if (typeof this.filters[type][func] === "function") {
		data = this.filters[type][func].apply(this, [data])
	}
	return data
}


/**
 * Checks for a load proxy on the container element.
 *
 * A load proxy allows the content to be loaded into an element that is not
 * the container element.  If the container element has a data-load="" property
 * set, then this function attempts to find the new container element.  The 
 * data-load property can be any valid jQuery selector.  If there is a data-load
 * property set, but the jQuery selector returns an empty result, the initial 
 * element is returned.
 * 
 * @param  {jQuery} $cntr A jQuery object containing the container element.
 * 
 * @return {jQuery}       A jQuery object containing the new elementif found, otherwise the initial object is returned.
 */
LinkParser.prototype._proxy_check = function($el) {
	var proxy = $el.data("load-proxy");
	return $(proxy).length ? $(proxy) : $el;
},

module.exports = LinkParser;
