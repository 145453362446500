module.exports = {
	findUpdates: function() {
		var json     = $("script#module-update").html();
		this.updates = JSON.parse(json);
		$("script#module-update").remove();
	},

	setBodyClass: function() {
		if (typeof this.updates.classes === "undefined") {
			return false;
		}

		var classes    = this.updates.classes
		,	classArray = []

		if (typeof classes === "object"  && !$.isArray(classes)) {
			for (var name in classes) {
				classArray.push(classes[name]);
			}
		} else if (typeof classes === "string") {
			classArray.push(classes);
		} else {
			classArray = classes;
		}

		$("body").attr("class", classArray.join(" "));
	},	
	
	setPageTitle: function() {
		if (typeof this.updates.title === "string") {
			document.title = this.util.decodeHtmlEntity(this.updates.title);
		}
	},

	activateNav: function() {
		if (typeof this.updates.root === "string") {
			$nav = $(".primary-navbar");
			$nav.find(".active").removeClass("active");
			$nav.find('[href="' + this.updates.root + '"]').closest('li').addClass("active")
		}
	},
	
	activeSection: function(event, $content) {
		var isString = typeof this.updates.section === "string"
		,	$section = isString ? this.util.id2jq(this.updates.section) : $content
		,	$section = $section.length ? $section : $content
		,	$section = this.util.maybeProxy($section)

		$section.elScroll({ offset: this.util.pageOffset() });
	},
}