var utils = require("../utils");
var mix   = require("./mixitup");

module.exports = {
	_init: function() {
		this.$el.select2({
			maximumSelectionSize: 1,
			width: "100%",
		})

		this.$el.on("change", $.proxy(this.filterRoute, this) );
	},

	filterRoute: function(event) {
		utils.route("blog/category/" + event.val);
		
		// Directly trigger a filter if target exists
		if ($(this.opts.target).length) {
			this.filter(event.val);
		}
	},

	filter: function(filter) {
		var filter = mix.sanitizeFilter(filter);
		$(this.opts.target).mixItUp("filter", filter);
	},

}