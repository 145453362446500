module.exports = {

	isSmall: function() {
		return $(window).width() < 767;
	},

	id2jq: function(id) {
		return $('[id="' + id +'"]');
	},

	pageOffset: function() {
		if( this.isSmall() ) {
			height = 0;
		} else {
			height = $(".header").height();
		}
		return height;
	},

	maybeProxy: function(el) {
		var	proxy = $(el).data("scroll-proxy");
		return proxy && $(proxy).length ? $(proxy) : $(el);
	},

	decodeHtmlEntity: function(string) {
	    var textArea = document.createElement('textarea');
	    textArea.innerHTML = string;
	    return textArea.value;
	},

	relativeLink: function(event, link) {
		if (event.altKey|| event.ctrlKey|| event.metaKey|| event.shiftKey) {
			return true;
		}
		event.preventDefault();

		var href   = $(link).attr('href');
		var	route  = href.replace(/^\//, '').replace('\#\!\/', '');
		var	active = window.location.pathname;

		this.route(route);

		(active === href) && this.Router.scroll(route);

		return false;
	},

	route: function(route, trigger) {
		this.Router.eventType = "click";
		this.Router.navigate(
			route, {
			trigger: true
		});
	},

};