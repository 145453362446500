module.exports = {
	_init: function() {
		this.$el.scrollClass({
			className: "showing",
			condition: function(scroll) {
				return scroll >= $( window ).height()/2;
			},
		}).on("click", function(e) {
			$("html").elScroll();
		})
	},
}