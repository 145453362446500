module.exports = {
	_init: function() {
		this.links   = this.$el.find(".filter-link");
		this.$active = this.$el.find(".menu-item.active");
		this.$arrow  = $( this.$el.data("indicator") );
		
		this.links.on("click.filter", $.proxy(this.clickActivate, this) );

		this.activate(this.$active, false);
	},

	clickActivate: function(event) {
		this.activate(event.target, true);
	},

	activate: function(slideTo, animate) {
		this.$active.closest(".menu-item").removeClass("active");
		$(slideTo).closest(".menu-item").addClass("active");
		this.$arrow.jSlide( $(slideTo), animate);
		this.$active = $(slideTo);
	},
}