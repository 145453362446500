
var ModuleLoader = require("./utils/module-loader");
var AppRouter    = require("./app");
var Utils        = require("./utils");

var Controller   = new ModuleLoader({
	search: ".faux-body",

	initialize: function() {
		var self = this;

		this.util.Router = new AppRouter;

		Backbone.history.start({ 
			pushState: true,
			hashChange: false,
			silent: true,
		});

		$(document).on("click", "a[href^='/']",	function(event) {
			extRegEx = /(?:\.([^.]+))?$/;
			if ( typeof extRegEx.exec($(this).attr("href"))[1] === "undefined" ) {
				self.util.relativeLink.apply(self.util, [event, this]);
			}
		});

		$(document).on("submit", "form", function(event) {
			$(this).closest(".backbone-container").elScroll({
				offset: Utils.pageOffset(), 
				duration: 0
			})
		})
		this.util.isSmall() && this.mobileInit();
	},

	reload: function() {
		$(document).scroll(); // Triggers scroll events after module initialization
	},

	mobileInit: function() {
		$(".collapsing-menu-container").click(function() {
			$(this).toggleClass("open");
		});
	},

	util: Utils,

	triggers: {
		animate: {
			event: "animate.pushload",
			actions: require("./actions/animate"),
			reload: true,
			searchTarget: true,
		},

		clone: {
			event: "clone.pushload",
			actions: require("./actions/clone"),
		},
	},

	modules: {
		blogmix:    require("./modules/blog-mix"),
		blogsearch: require("./modules/blog-search"),
		blogselect: require("./modules/blog-select"),
		carousel:   require("./modules/carousel"),
		collapse:   require("./modules/collapse"),
		filternav:  require("./modules/filter-nav"),
		home:       require("./modules/home"),
		map:        require("./modules/map"),
		mixitup:    require("./modules/mixitup"),
		navspy:     require("./modules/navspy"),
		scrolltop:  require("./modules/scroll-top"),
		stickynav:  require("./modules/stickynav"),
		subnav:     require("./modules/subnav"),
	}

});

module.exports = Controller;

