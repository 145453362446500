

module.exports = {

	location: new google.maps.LatLng(38.92916528895054,-77.24411951021442),

	popUpHTML: '<div class="map-info"><strong>The Jake Group</strong><br /><a class="directions-link" href="https://www.google.com/maps/place/the+jake+group" target="_blank">Get Directions</a></div>',

	styles: [
		{
			stylers: [
				{ hue: "#003768" },
				{ saturation: -20 }
			]
		},
		{
			featureType: "road",
			elementType: "geometry",
			stylers: [
				{ lightness: 100 },
				{ visibility: "simplified" }
			]
		},
		{
			featureType: "road",
			elementType: "labels",
		}
	],

	_init: function() {
		this.mapOptions = {
			center: this.location,
			zoom: 16,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			zoomControl: true,
		}

		this.canvas = this.$el[0];

		var styledMap = new google.maps.StyledMapType(this.styles, {name: "Styled Map"});
		this.map      = new google.maps.Map(this.canvas, this.mapOptions);
		this.marker   = new google.maps.Marker({
			position: this.location,
			map: this.map,
			title: 'The Jake Group',
			icon: '/assets/img/contact_map_marker.png',
		});
	
		this.map.mapTypes.set('map_style', styledMap);
		this.map.setMapTypeId('map_style');
		this.addInfoWindow();
	},

	addInfoWindow: function() {
		var self  = this;
		var popup = new google.maps.InfoWindow({
			content: this.popUpHTML
		});

		google.maps.event.addListener(this.marker, 'click', function() {
			popup.open(self.map, self.marker);
		});
	},


}

