var utils = require("../utils");

module.exports = {
	routes: [],

	_init: function() {
		this.$arrow = $( this.$el.data("indicator") );
		this.getRoutes();
		this.setIndicatorPosition();

		utils.Router.on("route", this.maybeActivateNav, this)
	},

	_destroy: function() {
		utils.Router.off(null, null, this);
	},

	getRoutes: function() {
		var self = this;
		this.$el.find("a").each(function() {
			self.routes.push( $(this).attr("href") )
		});
	},

	maybeActivateNav: function(route, data) {
		var url = "/"+data[0];
		if ( $.inArray(url, this.routes) !== -1 ) {
			this.activateNav(url);
		}
	},

	setIndicatorPosition: function() {
		var $slideTo = this.$el.find(".active")

		if (!$slideTo.length) {
			$slideTo = this.$el.find(".menu-item").first()
			this.$arrow.hide()
		}

		this.$arrow.jSlide($slideTo, false);
	},


	activateNav: function(url) {
		var $new = this.$el.find(".menu-item").has('[href="'+url+'"]');

		this.$el.find(".active").removeClass("active");
		$new.addClass("active");
		
		this.$arrow.jSlide($new, true).show();
	},

}