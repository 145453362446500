var utils = require("../utils");
var mix   = require("./mixitup");

module.exports = {

	_init: function() {
		this.$input = this.$el.find(".search-input");
		this.$el.on('submit', $.proxy(this.onSubmit, this) );
	},

	onSubmit: function(event) {
		event.preventDefault();
		var value  = this.$input.val().trim()

		if (value) {
			var search = value.replace(/\s/g, "+"); // replace whitespace with +
			this.$input.addClass("loading");
			this.findPosts(search);
		}
	},

	findPosts: function(value) {
		$.ajax({
			url: "/json/blog/search/"+value,
			dataType: "json"
		})
		.success( $.proxy(this.onSuccess, this, value) )
	},

	onSuccess: function(search, data) {
		utils.route("blog/search/" + search);
	
		if ( !$.isArray(data) ) {
			data = "none";
		}

		filter = mix.sanitizeFilter(data);

		if ($(this.opts.target).length) {
			this.filter( filter );
		}
	
		this.$input.removeClass("loading");
	},

	filter: function(filter) {
		$(this.opts.target).mixItUp("filter", filter);
	},

}