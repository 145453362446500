var utils = require("../utils");

module.exports = {
	_init: function(settings) {					
		$('.sticky-collapse').on('click.bs.collapse', '[data-toggle="collapse"]', this.stickyClick);
		$('[data-swap-target]').on('click.bs.collapse', this.swap);
	},

	swap: function(e) {
		e.preventDefault();

		var	swapTarget = $(this).data("swap-target")
		,	target     = $(this).data("target")
		,	swapText   = $(this).data("swap-text")
		,	text       = $(this).text()

		if ($(target).data("transitioning")) {
			return false;
		}
				
		$(this).data("swap-target", target);
		$(this).data("target", swapTarget);
		$(this).data("swap-text", text);
		$(this).text(swapText);

		$(target).on("shown hidden", function() {
			$(this).data("transitioning", false);
		});

		$(target).data("transitioning", true)
		$(target).collapse('show');
		$(swapTarget).collapse('hide');
	},

	stickyClick: function(event) {
		target = $(this).data("target") ? $(this).data("target") : $(this).attr("href");

		// If the target is already open, do not toggle
		if ( $(target).hasClass("in") ) {
			// Prevent other events from firing
			event.stopPropagation() && event.preventDefault(); 

			// If we are dealing with a link that we should route to
			if ( $(this).attr("href") && $(this).attr("href").match("^/") ) {
				// Manually call the routing function
				utils.relativeLink.call(utils, event, $(this));
			}
			return false;
		}
	}
}