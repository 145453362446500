module.exports = {
	_init: function() {
		this.setFilter(this.opts.filter);
		this.regex = new RegExp(this.opts.linkBase + "([^/]*)");

		this.preventActiveDelay();

		this.$el.mixItUp({
			animation: {
				effects: 'fade',
				duration: 600,
			},
			load: {
				filter: this.filter
			},
			layout: {
				containerClassFail: "no-posts"
			},
		});

		$('a[href^="'+this.opts.linkBase+'"]').on("click.filters", $.proxy(this.filterClick, this) );
	},

	_destroy: function() {
		this.$el.mixItUp('destroy');
		$('a[href^="'+this.opts.linkBase+'"]').off("click.filters");
	},

	filterClick: function(event) {
		event.preventDefault();
		var link   = event.target.pathname.match(this.regex);
		var filter = this.sanitizeFilter( link[1] );

		this.$el.mixItUp("filter", filter);
	},

	setFilter: function(filter) {
		filter = filter ? filter : this.filter;

		filter = this.sanitizeFilter(filter);

		this.filter = filter ? filter : "all";
	},

	sanitizeFilter: function(filter) {
		if ($.isArray(filter)) {
			filter = "." + filter.join(", ."); 
		} else if (filter !== "all" && filter !== "none") {
		
			if (typeof filter === "string" 
				&& !(filter.charAt(0) === "." || filter.charAt(0) === "#") 
			) {
				filter = "." + filter;
			}

		}

		return filter;
	},

	preventActiveDelay: function() {
		var selector = this.filter === "all" ? ".mix" : this.filter;
		$(selector).show(); // Prevents a delay in active state when loading
	}

}
